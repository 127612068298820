import React, { useEffect } from "react";
import { Button, message, Tabs } from "antd";
import Info from "./components/Info";
import api from "../../../utils/http";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab, setUser } from "../../../redux/Reducer";
import ItemCategoriesHOC from "../../../containers/shopOwner/shop/itemCategories/ItemCategoriesHOC";
import ItemsHOC from "../../../containers/shopOwner/shop/items/ItemsHOC";
import ModifiersHOC from "../../../containers/shopOwner/shop/modifiers/ModifiersHOC";
import ModifiersOptionsHOC from "../../../containers/shopOwner/shop/modifierOptions/ModifierOptionsHOC";
import DealsHOC from "../../../containers/shopOwner/shop/deals/DealsHOC";
import CouponsHOC from "../../../containers/shopOwner/shop/coupons/CouponsHOC";
import { useNavigate, useParams } from "react-router-dom";
import { RoutesNames } from "../../../router/RouteNames";
import LiveOrders from "../LiveOrders";
import { translations } from "../../../utils/translations";

const ShopForm = ({ shopData, setShopData, user }) => {
  const { put, post, postGlobal } = api();
  const navigate = useNavigate();
  const { id } = useParams();
  const { currentTab } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    id && dispatch(setUser({ ...user, shop: parseInt(id) }));
  }, []);

  const onInfoUpdate = () => {
    if (window.location.pathname.split("/")[2] === "add") {
      postGlobal("/shops", {
        ...shopData,
        user: {
          ...shopData.user,
          language: user.language,
        },
        files: shopData.files?.map((file) => file.id),
        schedule: shopData.schedule.map((day) => {
          if (day[0] === "0000" && day[1] === "0000") {
            return [];
          }
          return day.map((time) => time.replace(":", ""));
        }),
      })
        .then((res) => {
          message.success(" Shop added successfully");
          navigate(RoutesNames.SHOP);
        })
        .catch((err) => {
          message.error(err.response.data.message);
        });
    } else {
      put("", {
        ...shopData,
        user: {
          ...shopData.user,
          language: user.language,
        },
        schedule: shopData.schedule.map((day) => {
          if (day[0] === "0000" && day[1] === "0000") {
            return [];
          }
          return day.map((time) => time.replace(":", ""));
        }),
        files: shopData?.files?.map((file) => file.id),
        cuisines: shopData?.cuisines?.[0]?.id
          ? shopData?.cuisines?.map((cuisine) => cuisine.id)
          : shopData?.cuisines,
      })
        .then((res) => {
          message.success(" Shop updated successfully");
        })
        .catch((err) => {
          message.error(err.response.data.message);
        });
    }
  };

  const tabItems =
    window.location.pathname.split("/")[2] === "add"
      ? [
          {
            key: "1",
            label: translations.info,
            children: (
              <Info
                shopData={shopData}
                setShopData={setShopData}
                edit={false}
                onFinish={onInfoUpdate}
                user={user}
              />
            ),
          },
        ]
      : [
          {
            key: "1",
            label: translations.info,
            children: (
              <Info
                shopData={shopData}
                setShopData={setShopData}
                edit={true}
                onFinish={onInfoUpdate}
                user={user}
              />
            ),
          },
          // {
          //   key: "2",
          //   label: "Orders",
          //   children: <LiveOrders />,
          // },
          {
            key: "3",
            label: translations.categories,
            children: <ItemCategoriesHOC />,
          },
          {
            key: "4",
            label: translations.items,
            children: <ItemsHOC />,
          },
          {
            key: "5",
            label: translations.modifiers,
            children: <ModifiersHOC />,
          },
          {
            key: "6",
            label: translations.modifierOptions,
            children: <ModifiersOptionsHOC />,
          },
          {
            key: "7",
            label: translations.coupons,
            children: <CouponsHOC />,
          },
          // {
          //   key: "8",
          //   label: "Deals",
          //   children: <DealsHOC />,
          // },
        ];

  return (
    <div>
      {user.role === 0 && (
        <div style={{ float: "right" }}>
          <Button
            type="dashed"
            onClick={() => {
              navigate(-1);
              dispatch(setUser({ ...user, shop: null }));
            }}>
            {translations.cancel}
          </Button>
        </div>
      )}
      <Tabs
        style={{ width: "100%" }}
        defaultActiveKey={currentTab}
        onChange={(key) => {
          dispatch(setCurrentTab(key));
        }}
        items={tabItems}
      />
    </div>
  );
};

export default ShopForm;
