import React from "react";

const LogoImg = () => {
  return (
    <svg
      viewBox="0 0 388.22 567.8"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style={{ width: 140, height: 140, marginBottom: 20 }}>
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="310.37"
        x2="59.04"
        y1="-63.82"
        y2="528.46">
        <stop offset="0" stop-color="#31fe6c" />
        <stop offset="1" stop-color="#13a448" />
      </linearGradient>
      <path
        d="m158.57 211.32c13.06 13.99 59.04 9 74.35 8.67 29.82-.62 58.54-.33 88.39 0 6.07.07 12.8-.21 19.18 0 34.87 1.12 43.07 20.41 35.95 54.65-12.2 58.43-40.89 109.54-73.3 158.21-27.26 40.92-58.17 79.51-88.57 118.28-17.61 22.4-21.12 22.22-37.27-.51-28.87-40.67-90.38-132.06-92.71-136.33-10.38-19.01-30.06-33.36 6.34-33.36 24.49 0 46.69-.12 72.14.02 20.48.11 32.02-1.3 32.02 29.83 0 4.12 3.57 3.83 5.29 3.48 12.22-2.45 64.68-30.01 52.19-45.15-6.7-8.11-29.54-7.25-44.97-7.25h-116.85c-11.9 0-32.61-2.29-40.86-13.75-45.95-63.97-64.92-142.38-36.49-221.12 25.69-71.25 79.36-113.49 153.83-124.52 70.52-10.41 130.88 12.13 177.5 67.71 22 26.2 36.65 55.58 42.38 89.59 2.52 14.94 1.86 40.4-14.34 40.4-27.52 0-48.93-.6-74.32 0-14.01.33-29-.14-42.99-.36-24.99-.4-29.24-4.04-29.39-28.56-.07-12.79-82.05 24.48-67.51 40.07z"
        fill="url(#a)"
      />
    </svg>
  );
};

export default LogoImg;
