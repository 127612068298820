import React, { useEffect, useState } from "react";
import LanguagesView from "../../components/languages/LanguagesView";
import api from "../../utils/http";
import { message } from "antd";

const LanguagesHoc = () => {
  const { getGlobal, postGlobal } = api();

  const [data, setData] = useState([]);
  const [bool, setBool] = useState([]);

  useEffect(() => {
    getGlobal("/languages")
      .then((res) => setData(res.data.data))
      .catch((err) => message.error(err.response.data.message));
  }, [bool]);

  const onSave = (item) => {
    postGlobal("/languages", item)
      .then((res) => {
        setBool(!bool);
      })
      .catch((err) => message.error(err.response.data.message));
  };

  return <LanguagesView data={data} onSave={onSave} />;
};

export default LanguagesHoc;
