import React from "react";
import { Col, Flex, message, Row } from "antd";
import Order from "./Order";
import { useFocusPage } from "../../../utils/useFocusPage";

const LiveOrders = () => {
  const styles = {
    padding: 8,
    border: "1px solid #e8e8e8",
    backgroundColor: "#f7f7f7",
  };

  useFocusPage(() => message.info("Live Orders Page is focused"));

  return (
    <Flex vertical style={styles}>
      <Order
        orderNumber={111}
        orderAddress={"address 1"}
        orderDetails={[
          "pita KALAMAKI",
          "Kalamaki gia espressaki",
          "kai alla tetoia athinaika!",
        ]}
      />{" "}
      <Order
        orderNumber={111}
        orderAddress={"address 1"}
        orderDetails={[
          "pita KALAMAKI",
          "Kalamaki gia espressaki",
          "kai alla tetoia athinaika!",
        ]}
      />
    </Flex>
  );
};

export default LiveOrders;
