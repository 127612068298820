import React from "react";
import { Button, Card, Flex, Typography } from "antd";
import ModifierOptionROw from "./ModifierOptionRow";
import { translations } from "../../../../utils/translations";

const { Title } = Typography;

const ModifierOptionsForm = ({ edit, item, setItem, onFinish, user }) => {
  return (
    <Flex vertical justify="center" align="flex-start">
      <Title level={3}>{translations.modifierOption}</Title>
      <br />
      <Flex>
        <ModifierOptionROw setItem={setItem} item={item} />
      </Flex>
      <br />
      <sFlex>
        <Button
          type="primary"
          style={{ marginRight: 12 }}
          onClick={() => window.history.back()}>
          {translations.cancel}
        </Button>

        <Button type="primary" onClick={onFinish}>
          {translations.submit}
        </Button>
      </sFlex>
    </Flex>
  );
};
export default ModifierOptionsForm;
