import React, { useEffect } from "react";
import { TestPdf } from "./pdfs/TestPdf";
import { PrintButton } from "./pdfs/Wrapper";

const Print = () => {
	
	// useEffect(() => {
	// 	window?.print && window.print()
	// },[])

  return (
    <div>
      Print
      {/* <TestPdf size='A4'/>
		<TestPdf size={58}/>
		<TestPdf size={150}/> */}
      <PrintButton />
    </div>
  );
};

export default Print;
// import React, { useRef } from "react";
// import { Button } from "antd";

// const JsonToPdfPrinter = ({ jsonData }) => {
//   const printRef = useRef(null);

//   // Function to handle the print directly
//   const handlePrint = () => {
//     // Convert JSON to a string without circular references
//     const safeJsonData = JSON.stringify({ xa: 1 });

//     // Set the content to the printRef div
//     if (printRef.current) {
//       printRef.current.innerText = safeJsonData;

//       // Use window print to print only the specific content
//       const originalContent = document.body.innerHTML;
//       const printContent = printRef.current.innerHTML;

//       // Replace body content with the printable content
//       document.body.innerHTML = `<div>${printContent}</div>`;
//       window.print();

//       // Restore the original content after printing
//       document.body.innerHTML = originalContent;
//       window.location.reload(); // Reload to reset the application state
//     }
//   };

//   return (
//     <div>
//       <Button type="primary" onClick={handlePrint}>
//         Print JSON as PDF
//       </Button>
//       {/* Hidden div to hold the printable content */}
//       <div ref={printRef} style={{ display: "none" }} />
//     </div>
//   );
// };

// export default JsonToPdfPrinter;
