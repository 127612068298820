import React, { useEffect, useCallback, useState } from "react";
import { message, Select, Spin } from "antd";
import debounce from "lodash/debounce";
import api from "../../../utils/http";
import { multiply, set, uniqBy } from "lodash";

const { Option } = Select;

const PaginatedSelect = ({
  basePath,
  user,
  parentData,
  setParentData,
  name,
  params,
  multiple,
}) => {
  const { get, getGlobal } = api();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setPageNumber(1);
  }, [basePath]);

  const fetchDataForUserShop = () => {
    setLoading(true);
    get(basePath, {
      pageNumber,
      ...(params && { params }),
      ...(search && { name: search }),
      pageSize: 30,
      language: user?.language,
    })
      .then((response) => {
        setTotalPages(response.data.totalPages);
        setData((prevData) =>
          uniqBy([...prevData, ...response.data.data], "id")
        );
        setHasMore(response.data.pageNumber < response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.response.data.message);
      });
  };
  const fetchDataForAdmin = () => {
    setLoading(true);
    getGlobal(basePath, {
      pageNumber,
      ...(params && { params }),
      ...(search && { name: search }),
      pageSize: 30,
      language: user?.language,
    })
      .then((response) => {
        setTotalPages(response.data.totalPages);
        setData((prevData) =>
          uniqBy([...prevData, ...response.data.data], "id")
        );
        setHasMore(response.data.pageNumber < response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.response.data.message);
      });
  };

  useEffect(() => {
    search && setData([]);
    user.role === 0 ? fetchDataForAdmin() : fetchDataForUserShop();
  }, [pageNumber, search]);

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setPageNumber(1);
      setHasMore(true);
    }, 500),
    []
  );

  const handleScroll = (e) => {
    const { target } = e;
    if (
      target.scrollTop + target.clientHeight >= target.scrollHeight - 100 &&
      pageNumber < totalPages &&
      hasMore &&
      !loading
    ) {
      setPageNumber((prevPage) => prevPage + 1);
    }
  };

  const checkParentDataFormat = (parent) => {
    if (parent && parent[0]?.name) {
      return parent.map((x) => x?.id);
    } else if (parent && parseInt(parent) > 0) {
      return parent;
    } else {
      return [];
    }
  };

  return (
    <Select
      showSearch
      mode={multiple}
      onSearch={handleSearch}
      filterOption={false}
      onPopupScroll={handleScroll}
      value={checkParentDataFormat(parentData?.[name])}
      notFoundContent={loading ? <Spin size="small" /> : null}
      style={{ width: "100%" }}
      onChange={(value) => {
        setParentData({ ...parentData, [name]: value?.id || value });
      }}>
      {data.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
};

export default PaginatedSelect;
