import React, { useEffect } from "react";
import {
  Button,
  Card,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Switch,
  Typography,
} from "antd";
import PaginatedSelect from "../../../UI/Select";
import UploadImage from "../../../UI/UploadImage";
import { translations } from "../../../../utils/translations";

const { Title } = Typography;

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 12,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
  },
  number: {
    range: "${label} must be a valid mobile number! (10 digits)",
  },
};
const ItemForm = ({ edit, onFinish, item, setItem, user }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: item?.name,
      description: item?.description,
      price: item?.price,
      available: item?.available,
      files: item?.files,
      category: item?.category,
      modifiers: item?.modifiers,
    });
  }, [item]);

  return (
    <div>
      <Title level={3}>{translations.item}</Title>
      <br />
      <Form
        form={form}
        {...layout}
        name="Item"
        onFinish={onFinish}
        validateMessages={validateMessages}>
        {!edit && (
          <Form.Item
            style={{ width: "100%" }}
            name={["category"]}
            label={translations.category}>
            <Flex align="center">
              <PaginatedSelect
                basePath={`/item-categories`}
                user={user}
                parentData={item}
                setParentData={setItem}
                name={"category"}
              />
            </Flex>
          </Form.Item>
        )}

        {item?.category && (
          <Flex vertical>
            <Form.Item
              style={{ width: "100%" }}
              name={["name"]}
              label={translations.name}
              rules={[
                {
                  required: true,
                },
              ]}>
              <Flex align="center">
                <Input
                  value={item?.name}
                  onChange={({ target: { value } }) => {
                    setItem({ ...item, name: value });
                  }}
                  style={{
                    marginRight: 12,
                    marginBottom: 12,
                    width: "70svw",
                  }}
                />
              </Flex>
            </Form.Item>
            <Form.Item
              style={{ width: "100%" }}
              name={["description"]}
              label={translations.description}>
              <Flex align="center">
                <Input
                  value={item?.description}
                  onChange={({ target: { value } }) => {
                    setItem({ ...item, description: value });
                  }}
                  style={{
                    marginRight: 12,
                    marginBottom: 12,
                    width: "70svw",
                  }}
                />
              </Flex>
            </Form.Item>
            <Form.Item
              style={{ width: "100%" }}
              name={["price"]}
              label={translations.price}
              rules={[
                {
                  required: true,
                },
              ]}>
              <Flex align="center">
                <InputNumber
                  value={item?.price}
                  onChange={(value) => {
                    String(value).length < 6
                      ? setItem({ ...item, price: Number(value) })
                      : null;
                  }}
                  style={{
                    marginRight: 12,
                    marginBottom: 12,
                    width: "70svw",
                  }}
                />
              </Flex>
            </Form.Item>
            <Form.Item
              style={{ width: "100%" }}
              name={["available"]}
              label={translations.available}>
              <Flex align="center">
                <Switch
                  checked={item?.available}
                  onChange={(value) => {
                    setItem({ ...item, available: value });
                  }}
                />
              </Flex>
            </Form.Item>
            <Form.Item
              style={{ width: "100%" }}
              name={["modifiers"]}
              label={translations.options}>
              <Flex align="center">
                <PaginatedSelect
                  basePath={`/modifiers`}
                  user={user}
                  parentData={item}
                  setParentData={setItem}
                  name={"modifiers"}
                  multiple={"multiple"}
                />
              </Flex>
            </Form.Item>
            <Form.Item style={{ width: "100%" }} name={["files"]} label="Image">
              <Flex align="center">
                <UploadImage
                  parentData={item}
                  setParentData={setItem}
                  array={[translations.main]}
                />
              </Flex>
            </Form.Item>
          </Flex>
        )}
        <br />
        <br />
        <Form.Item
          style={{ width: "100%" }}
          wrapperCol={{
            ...layout.wrapperCol,
            offset: 8,
          }}>
          <Button type="primary" htmlType="submit">
            {translations.submit}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default ItemForm;
