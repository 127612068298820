import React from "react";
import { Button, Flex, Form, Input, Table, Typography } from "antd";
import { translations } from "../../utils/translations";
const { Title } = Typography;

const LanguagesView = ({ data, onSave }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "65%",
    },
    {
      title: "Code",
      dataIndex: "code",
      width: "25%",
    },
  ];

  return (
    <div>
      <Title>{translations.languages}</Title>
      <Table bordered dataSource={data} columns={columns} pagination={false} />
      <div style={{ marginBottom: "10svh" }} />
      <Title>{translations.addLanguage}</Title>
      <Form
        name="wrap"
        labelCol={{
          flex: "110px",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        onFinish={onSave}
        colon={false}
        style={{
          maxWidth: 600,
        }}>
        <Flex>
          <Form.Item
            label={translations.name}
            name="name"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label={translations.code}
            name="code"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item label=" ">
            <Button type="primary" htmlType="submit">
              {translations.save}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </div>
  );
};
export default LanguagesView;
