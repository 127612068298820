import React, { useEffect, useState } from "react";
import { Button, Flex, Table, Input, Space, message, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { DragOutlined } from "@ant-design/icons"; // Import the drag handle icon
import api from "../../../utils/http";
import { translations } from "../../../utils/translations";
import { setCurrentTab, setUser } from "../../../redux/Reducer";

const { Text } = Typography;

const SortableItem = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "grab", // Add a cursor style for better UX
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <span {...listeners}>
        <DragOutlined style={{ marginRight: 8 }} />
      </span>
      {children}
    </div>
  );
};

const MyTable = ({
  data,
  setOpen,
  modalFlag,
  columns,
  setPageNumber,
  pageSize,
  setPageSize,
  editPath,
  basePath,
  setReduxAction,
  setItem,
  totalPages,
  queryParams,
  draggable,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const { get, del, deleteGlobal, getGlobal } = api();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState([]);
  const [sortableData, setSortableData] = useState(data);

  useEffect(() => {
    setSortableData(data); // Update sortable data when data prop changes
  }, [data]);

  const onSelectChange = (newSelectedRowKeys, item) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onSearch = (value) => {
    setSearchText(value);
    applyFilters(value);
  };

  const applyFilters = (searchText) => {
    const fetchFunction = user?.shop ? get : getGlobal;
    fetchFunction(basePath, {
      pageSize,
      pageNumber: 1,
      ...(searchText && { name: searchText }),
    })
      .then((res) => {
        res.data.data.length > 0
          ? dispatch(setReduxAction(res.data.data))
          : message.warning("No data found");
      })
      .catch((err) => {
        message.error(err.response?.data.message);
      });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = sortableData.findIndex((item) => item.id === active.id);
      const newIndex = sortableData.findIndex((item) => item.id === over.id);

      const reorderedData = arrayMove(sortableData, oldIndex, newIndex);
      setSortableData(reorderedData);
      // You can also update the order in the server/database if needed
    }
  };

  const rowClassName = (record, index) =>
    index % 2 === 1 ? "custom-table-row" : "";

  console.log({ data });

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext items={sortableData.map((item) => item.id)}>
        <Flex gap="middle" vertical>
          <Space style={{ marginBottom: 16 }}>
            <Input
              placeholder={translations.nameSearch}
              onSearch={onSearch}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              style={{ width: 200 }}
            />

            <Button
              disabled={searchText.length < 3}
              type="primary"
              onClick={() => applyFilters(searchText)}>
              {translations.apply}
            </Button>
            <Button
              onClick={() => {
                setSearchText("");
                applyFilters(false);
              }}>
              {translations.reset}
            </Button>
          </Space>
          <div style={{ height: "2svh" }}>
            {selectedRowKeys.length > 0 && (
              <Flex justify="flex-start" align="center">
                <Button
                  style={{ width: "100px", marginRight: 12 }}
                  danger
                  onClick={() => {
                    const deleteFunction = modalFlag ? del : deleteGlobal;

                    deleteFunction(basePath, selectedRowKeys, queryParams)
                      .then((res) => {
                        message.success(
                          selectedRowKeys.length > 1
                            ? `IDs : ${selectedRowKeys}` + translations.haveDel
                            : `ID : ${selectedRowKeys}` + translations.hasDel
                        );
                        setTimeout(() => {
                          const fetchFunction = modalFlag ? get : getGlobal;

                          fetchFunction(basePath, {
                            pageSize: 10,
                            pageNumber: 1,
                          })
                            .then((res) => {
                              res.data.data.length > 0
                                ? dispatch(setReduxAction(res.data.data))
                                : message.warning("No data found");
                            })
                            .catch((err) => {
                              message.error(err.response?.data.message);
                            });
                        }, 1600);
                      })
                      .catch((err) => {
                        message.error(err.response?.data.message);
                      });
                  }}>
                  {translations.delete}
                </Button>

                <Text>
                  {hasSelected
                    ? translations.selected +
                      ` ${selectedRowKeys.length} ` +
                      translations.items
                    : null}
                </Text>
              </Flex>
            )}
          </div>
          <Table
            rowKey="id"
            rowSelection={rowSelection}
            rowClassName={rowClassName}
            columns={[
              ...columns,
              draggable
                ? {
                    title: "Reorder",
                    dataIndex: "drag",
                    width: 30,
                    render: (_, record) => <SortableItem id={record.id} />,
                  }
                : { title: "", dataIndex: "drag", width: 30 },
            ]}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (modalFlag) {
                    setOpen(true);
                    setItem(record);
                  } else {
                    dispatch(setUser({ ...user, shop: record.id }));
                    dispatch(setCurrentTab("1"));
                    navigate(editPath + record.id);
                  }
                },
              };
            }}
            dataSource={sortableData}
            pagination={{
              total: totalPages * pageSize,
              pageSize,
              showQuickJumper: true,
              responsive: true,
              onChange: (pg, pgSize) => {
                setPageNumber(pg);
                setPageSize(pgSize);
              },
            }}
          />
        </Flex>
      </SortableContext>
    </DndContext>
  );
};

export default MyTable;
