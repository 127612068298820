import React from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Flex, TimePicker } from "antd";

dayjs.extend(customParseFormat);

const Schedule = ({ setShopData, shopData }) => {
  const days = [
    "Δευτέρα",
    "Τρίτη",
    "Τετάρτη",
    "Πέμπτη",
    "Παρασκευή",
    "Σάββατο",
    "Κυριακή",
  ];
  const format = "HH:mm";

  return days.map((day, index) => (
    <Flex justify="flex-start" align="center" style={{ width: "100svw" }}>
      <span style={{ width: 150 }}>{day}</span>
      <TimePicker.RangePicker
        order={false}
        style={{ width: 200 }}
        minuteStep={15}
        defaultValue={["00:00", "00:00"]}
        value={[
          dayjs(
            shopData?.schedule[index][0].slice(0, 2) +
              ":" +
              shopData?.schedule[index][0].slice(2),
            format
          ),
          dayjs(
            shopData?.schedule[index][1].slice(0, 2) +
              ":" +
              shopData?.schedule[index][1].slice(2),
            format
          ),
        ]}
        format={format}
        onChange={(time, timeString) => {
          time === null
            ? setShopData({
                ...shopData,
                schedule: shopData.schedule.map((item, i) =>
                  i === index ? ["0000", "0000"] : item
                ),
              })
            : setShopData({
                ...shopData,
                schedule: shopData.schedule.map((item, i) =>
                  i === index ? timeString : item
                ),
              });
        }}
      />
    </Flex>
  ));
};

export default Schedule;
