import { useEffect } from "react";

export const useFocusPage = (callback) => {
  useEffect(() => {
    const cb = () => {
      if (document.visibilityState === "visible") {
        callback();
      }
    };
    window.addEventListener("visibilitychange", cb);
    return () => {
      window.removeEventListener("visibilitychange", cb);
    };
  }, []);
};
