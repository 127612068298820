import React, { useEffect, useState, useCallback } from "react";
import {
  GoogleMap,
  MarkerF,
  Polyline,
  Circle,
  DrawingManager,
} from "@react-google-maps/api";
import { geocodeByAddress } from "react-google-places-autocomplete";
import { Button, ColorPicker, Flex, Input, Typography } from "antd";

const defaultLat = 37.9838;
const defaultLng = 23.7275;

const { Text } = Typography;

const Map = ({ readOnly = false, data, setData }) => {
  const [marker, setMarker] = useState({
    position: {
      lat: defaultLat,
      lng: defaultLng,
    },
    label: { color: "white", text: "O" },
  });

  const [selectedShape, setSelectedShape] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const containerStyle = {
    width: "80svw",
    height: "80svh",
  };

  const loadGoogleMapsScript = (callback) => {
    if (typeof window.google === "undefined") {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${
        import.meta.env.VITE_GGL_API_KEY
      }&libraries=places,drawing`;
      script.async = true;
      script.defer = true;
      script.onload = callback;
      document.head.appendChild(script);
    } else {
      callback();
    }
  };

  useEffect(() => {
    loadGoogleMapsScript(() => setMapLoaded(true));
  }, []);

  useEffect(() => {
    if (data?.address?.lat && data?.address?.lng) {
      setMarker((prev) => {
        if (
          prev.position.lat !== data.address.lat ||
          prev.position.lng !== data.address.lng
        ) {
          return {
            ...prev,
            position: { lat: data.address.lat, lng: data.address.lng },
          };
        }
        return prev;
      });
    }
  }, [data]);

  const geocodeAddress = useCallback((address) => {
    return (
      window?.google &&
      geocodeByAddress(address)?.then((results) => {
        if (results.length > 0) {
          const location = results[0].geometry.location;
          const lat = location.lat();
          const lng = location.lng();
          const postalCodeComponent = results[0].address_components.find(
            (component) => component.types.includes("postal_code")
          );
          const postalCode = postalCodeComponent
            ? postalCodeComponent.long_name
            : "";
          return { lat, lng, postalCode };
        }
        throw new Error("No geocoding results found.");
      })
    );
  }, []);

  useEffect(() => {
    if (
      data?.address?.street &&
      data?.address?.streetNumber &&
      data?.address?.area
    ) {
      const addressString = `${data.address.street} ${data.address.streetNumber} ${data.address.area} `;
      geocodeAddress(addressString)
        ?.then(({ lat, lng, postalCode }) => {
          if (
            lat !== data.address.lat ||
            lng !== data.address.lng ||
            postalCode !== data.address.postalCode
          ) {
            setData((prev) => ({
              ...prev,
              address: { ...prev.address, lat, lng, postalCode },
            }));
            setMarker((prev) => ({
              ...prev,
              position: { lat, lng },
            }));
          }
        })
        .catch((error) => {
          console.error("Error in geocoding: ", error);
          setMarker((prev) => ({
            ...prev,
            position: { lat: defaultLat, lng: defaultLng },
          }));
        });
    }
  }, [
    data?.address?.street,
    data?.address?.streetNumber,
    data?.address?.area,
    geocodeAddress,
    setData,
  ]);

  const getAddress = useCallback((lat, lng) => {
    if (!window.google || !window.google.maps) {
      throw new Error("Google Maps API is not loaded yet.");
    }
    const geocoder = new window.google.maps.Geocoder();
    const latlng = new window.google.maps.LatLng(lat, lng);
    const request = { location: latlng };

    return new Promise((resolve, reject) => {
      geocoder.geocode(request, (results, status) => {
        if (status === "OK" && results.length > 0) {
          resolve(
            `${results[0].address_components[1].long_name} @ ${results[0].address_components[0].long_name} @ ${results[0].address_components[2].long_name} @ ${results[0].address_components[5].long_name}`
          );
        } else {
          reject(new Error("No results found"));
        }
      });
    });
  }, []);

  const mapClicked = useCallback(
    async (event) => {
      if (!readOnly) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        if (lat !== marker.position.lat || lng !== marker.position.lng) {
          try {
            const address = await getAddress(lat, lng);
            setData({
              ...data,
              address: {
                ...data.address,
                street: address.split("@")[0].trim(),
                streetNumber: address.split("@")[1].trim(),
                area: address.split("@")[2].trim(),
                postalCode: address.split("@")[3].trim(),
                lat,
                lng,
              },
            });
            setMarker((prev) => ({
              ...prev,
              position: { lat, lng },
            }));
          } catch (error) {
            console.error("Error getting address: ", error);
          }
        }
      }
    },
    [getAddress, readOnly, setData, data, marker.position]
  );

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const onPolylineComplete = useCallback(
    (polyline) => {
      const path = polyline
        .getPath()
        .getArray()
        .map((latLng) => ({
          lat: latLng.lat(),
          lng: latLng.lng(),
        }));

      const randomColor = getRandomColor(); // Generate a random color for the new polyline

      setData((prev) => ({
        ...prev,
        areas: [
          ...(prev.areas || []),
          {
            type: 1,
            minimumOrder: null,
            deliveryCost: null,
            polygon: path.map((coord) => [coord.lng, coord.lat]),
            fillColor: randomColor,
          },
        ],
      }));

      polyline.setMap(null);
    },
    [setData]
  );

  const onCircleComplete = useCallback(
    (circle) => {
      const center = circle.getCenter();
      const radius = circle.getRadius();
      const randomColor = getRandomColor();

      setData((prev) => ({
        ...prev,
        areas: [
          ...(prev.areas || []),
          {
            type: 0,
            minimumOrder: null,
            deliveryCost: null,
            circleCenter: {
              lat: center.lat(),
              lng: center.lng(),
            },
            radius: radius,
            fillColor: randomColor,
          },
        ],
      }));

      circle.setMap(null);
    },
    [setData]
  );

  const deleteSelectedShape = () => {
    if (selectedShape) {
      setData((prev) => ({
        ...prev,
        areas: prev.areas.filter((_, index) => index !== selectedShape.index),
      }));

      setSelectedShape(null);
    }
  };

  const handleInputChange = (index, field, value) => {
    setData((prev) => ({
      ...prev,
      areas: prev.areas.map((area, i) => {
        if (i === index) {
          return {
            ...area,
            [field]: Number(value),
          };
        }
        return area;
      }),
    }));
  };

  return mapLoaded ? (
    <div>
      {selectedShape && (
        <Button
          danger
          onClick={deleteSelectedShape}
          style={{ marginTop: "10px", marginBottom: "10px" }}>
          Delete Selected{" "}
          {selectedShape.type === "polyline" ? "Polyline" : "Circle"}
        </Button>
      )}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={marker.position}
        zoom={12}
        onClick={mapClicked}>
        <MarkerF
          position={marker.position}
          label={marker.label}
          draggable={!readOnly}
          onDragEnd={(event) => {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();

            if (lat !== marker.position.lat || lng !== marker.position.lng) {
              getAddress(lat, lng).then((address) => {
                setData({
                  ...data,
                  address: {
                    ...data.address,
                    street,
                    streetNumber,
                    area,
                    postalCode,
                    lat,
                    lng,
                  },
                });
                setMarker((prev) => ({
                  ...prev,
                  position: { lat, lng },
                }));
              });
            }
          }}
        />

        {window.google && window.google.maps && (
          <DrawingManager
            onPolylineComplete={onPolylineComplete}
            onCircleComplete={onCircleComplete}
            options={{
              drawingControl: true,
              drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                  window.google.maps.drawing.OverlayType.POLYLINE,
                  window.google.maps.drawing.OverlayType.CIRCLE,
                ],
              },
              polylineOptions: {
                strokeOpacity: 1.0,
                strokeWeight: 3,
                editable: true,
                draggable: false,
              },
              circleOptions: {
                fillOpacity: 0.3,
                strokeWeight: 1,
                clickable: true,
                editable: true,
                zIndex: 1,
              },
            }}
          />
        )}

        {data.areas?.map((area, index) => {
          if (area.type === 0 && area.circleCenter) {
            return (
              <Circle
                key={`circle-${index}`}
                center={{
                  lat: area.circleCenter.lat,
                  lng: area.circleCenter.lng,
                }}
                radius={area.radius}
                options={{
                  fillColor: area.fillColor,
                  fillOpacity: 0.3,
                  strokeWeight: 1,
                  clickable: true,
                  editable: true,
                  zIndex: 2,
                }}
                onClick={() => setSelectedShape({ type: "circle", index })}
              />
            );
          }

          if (area.type === 1 && area.polygon) {
            const path = area.polygon.map(([lng, lat]) => ({ lat, lng }));
            return (
              <Polyline
                key={`polyline-${index}`}
                path={path}
                options={{
                  strokeColor: area.fillColor,
                  strokeOpacity: 1.0,
                  strokeWeight: 3,
                  clickable: true,
                  zIndex: 2,
                }}
                onClick={() => setSelectedShape({ type: "polyline", index })}
              />
            );
          }
          return null;
        })}
      </GoogleMap>
      <br />
      {data.areas?.filter((x) => x.type === 1).length > 0 && (
        <Text>Polylines</Text>
      )}
      {data.areas?.length > 0 &&
        data.areas?.map((area, index) => {
          if (area.type === 1) {
            return (
              <Flex
                key={`polyline-flex-${index}`}
                style={{ marginTop: 12, marginBottom: 6, width: "70svw" }}
                justify="space-between"
                align="center">
                <ColorPicker value={area.fillColor} disabled={true} />
                <Flex vertical style={{ width: "45%" }}>
                  <Text>Minimum order</Text>
                  <Input
                    placeholder="Minimum order"
                    value={area.minimumOrder}
                    onChange={(e) =>
                      handleInputChange(index, "minimumOrder", e.target.value)
                    }
                  />
                </Flex>
                <Flex vertical style={{ width: "45%" }}>
                  <Text>Delivery cost</Text>
                  <Input
                    placeholder="Delivery cost"
                    value={area.deliveryCost}
                    onChange={(e) =>
                      handleInputChange(index, "deliveryCost", e.target.value)
                    }
                  />
                </Flex>
              </Flex>
            );
          }
          return null;
        })}
      <br />

      {data.areas?.filter((x) => x.type === 0).length > 0 && (
        <Text>Circles</Text>
      )}
      {data.areas?.length > 0 &&
        data.areas?.map((area, index) => {
          if (area.type === 0) {
            return (
              <Flex
                key={`circle-flex-${index}`}
                style={{ marginTop: 12, marginBottom: 6, width: "70svw" }}
                justify="space-between"
                align="center">
                <ColorPicker value={area.fillColor} disabled={true} />
                <Flex vertical style={{ width: "45%" }}>
                  <Text>Minimum order</Text>{" "}
                  <Input
                    placeholder="Minimum order"
                    value={area.minimumOrder}
                    onChange={(e) =>
                      handleInputChange(index, "minimumOrder", e.target.value)
                    }
                  />{" "}
                </Flex>
                <Flex vertical style={{ width: "45%" }}>
                  <Text>Delivery cost</Text>
                  <Input
                    placeholder="Delivery cost"
                    value={area.deliveryCost}
                    onChange={(e) =>
                      handleInputChange(index, "deliveryCost", e.target.value)
                    }
                  />
                </Flex>
              </Flex>
            );
          }
          return null;
        })}
    </div>
  ) : (
    <div>Loading Map...</div>
  );
};

export default Map;
