import React, { useEffect, useState } from "react";
import UserForm from "../../components/users/UserForm";
import { useSelector } from "react-redux";
import { message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { RoutesNames } from "../../router/RouteNames";
import api from "../../utils/http";

const EditUserHOC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useSelector((state) => state);

  const { putGlobal, getGlobal } = api();

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    params.id &&
      getGlobal(`/users/${params.id}`)
        .then((response) => {
          setUserData(response.data);
        })
        .catch((err) => {
          message.error(err.response.data.message);
        });
  }, [params.id]);

  const onFinish = (values) =>
    putGlobal(`/users/${params.id}`, {
      ...values,
      role: userData.role,
      language: user?.language,
    })
      .then(() => {
        message.success("User updated successfully");
        navigate(RoutesNames.USERS);
      })
      .catch((error) => message.error(error.response.data.message));

  return <UserForm onFinish={onFinish} userData={userData} edit={true} />;
};

export default EditUserHOC;
