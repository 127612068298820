import axios from "axios";
import { store } from "../redux/Store"; // Adjust the path according to your project structure

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state?.user?.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// TODO Export each one of them separately
const api = () => {
  const isAdmin = store.getState()?.user?.role === 0;
  const shopID = store.getState()?.user?.shop;

  //admin routes
  const getGlobal = (url, params = {}, config = {}) =>
    axiosInstance.get(url, { ...config, params });
  const postGlobal = (url, data, config = {}) =>
    axiosInstance.post(url, data, config);
  const putGlobal = (url, data, config = {}) =>
    axiosInstance.put(url, data, config);
  const deleteGlobal = (
    url,
    selectedRowKeys = [],
    queryParams = "",
    config = {}
  ) => {
    const queryString = selectedRowKeys
      .map((id) => `${queryParams}=${id}`)
      .join("&");
    return axiosInstance.delete(`${url}?${queryString}`, config);
  };

  // with shop id routes
  const get = (url, params = {}, config = {}) =>
    axiosInstance.get(
      isAdmin ? `/shops/${shopID}${url}` : `/shops-owners/shop${url}`,
      {
        ...config,
        params,
      }
    );

  const post = (url, data, config = {}) =>
    axiosInstance.post(
      isAdmin ? `/shops/${shopID}${url}` : `/shops-owners/shop${url}`,
      data,
      config
    );
  const patch = (url, data, config = {}) =>
    axiosInstance.patch(
      isAdmin ? `/shops/${shopID}${url}` : `/shops-owners/shop${url}`,
      data,
      config
    );
  const put = (url, data, config = {}) =>
    axiosInstance.put(
      isAdmin ? `/shops/${shopID}${url}` : `/shops-owners/shop${url}`,
      data,
      config
    );
  const del = (url, selectedRowKeys = [], queryParams = "", config = {}) => {
    // Manually build the query string
    const queryString = selectedRowKeys
      .map((id) => `${queryParams}=${id}`)
      .join("&");

    // Perform the delete request with the correctly formatted URL
    return axiosInstance.delete(
      `${
        isAdmin ? `/shops/${shopID}${url}` : `/shops-owners/shop${url}`
      }?${queryString}`,
      config
    );
  };

  return {
    get,
    post,
    patch,
    put,
    del,
    getGlobal,
    postGlobal,
    putGlobal,
    deleteGlobal,
  };
};

export default api;
