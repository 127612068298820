import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { clearPersistedState, setUser } from "../../redux/Reducer";
import { RoutesNames } from "../../router/RouteNames";
import LoginForm from "../../components/login/LoginForm";
import { useNavigate } from "react-router-dom";
import api from "../../utils/http";

const LoginHOC = () => {
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({ userName: "", password: "" });
  const { user } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { postGlobal, getGlobal } = api();

  useEffect(() => {
    user && user.token && navigate("/");
  }, [user]);

  const onFinish = () => {
    dispatch(clearPersistedState());
    postGlobal("/auth/login", {
      email: loginForm.userName,
      password: loginForm.password,
    })
      .then((response) => {
        const expirationTime = Date.now() + response.data.expires * 1000;
        dispatch(
          setUser({
            token: response.data.token,
            expirationTime,
            refreshToken: response.data.refreshToken,
          })
        );
        setTimeout(() => {
          getGlobal("/my")
            .then((res) => {
              dispatch(
                setUser({
                  token: response.data.token,
                  expirationTime,
                  refreshToken: response.data.refreshToken,
                  ...res.data,
                })
              );
            })
            .catch((err) => {
              message.error(err.response.data.message);
            });
        }, 500);
        navigate(RoutesNames.HOME);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  return (
    <LoginForm
      setLoginForm={setLoginForm}
      loginForm={loginForm}
      onFinish={onFinish}
    />
  );
};

export default LoginHOC;
