import React, { useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { RoutesNames } from "../../router/RouteNames";
import api from "../../utils/http";
import { useSelector } from "react-redux";
import CityForm from "../../components/cities/CityForm";

const AddCityHOC = () => {
  const { user } = useSelector((state) => state);
  const navigate = useNavigate();
  const { postGlobal } = api();

  const [item, setItem] = useState({ name: "" });

  const onFinish = () =>
    postGlobal("/cities", {
      area: {
        type: 0,
        circleCenter: {
          lat: item.lat,
          lng: item.lng,
        },
        radius: parseInt(item.radius),
      },
      name: item.name,
      language: user.language,
    })
      .then(() => {
        message.success("city created successfully");
        navigate(RoutesNames.CITIES);
      })
      .catch((error) => message.error(error.response.data.message));

  return (
    <CityForm
      edit={false}
      onFinish={onFinish}
      cityData={item}
      setCityData={setItem}
    />
  );
};

export default AddCityHOC;
