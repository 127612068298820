import React from "react";
import { Button, Flex, Input, Switch, Table, Typography } from "antd";
import MyTable from "../../../UI/Table";
import { setCoupons } from "../../../../redux/Reducer";
import MyModal from "../../../UI/Modal";
import { CouponsColumns } from "../../../../columns/CouponsColumns";
import { translations } from "../../../../utils/translations";

const { Text } = Typography;

const CouponsTable = ({
  data,
  setPageNumber,
  pageSize,
  setPageSize,
  totalPages,
  item,
  setItem,
  onFinish,
  open,
  setOpen,
  user,
  onActiveChange,
}) => {
  return (
    <Flex vertical>
      <Flex justify="space-between" align="center">
        <h2>{translations.coupons}</h2>
        <Button type="primary" onClick={() => setOpen(true)}>
          {translations.addNew}
        </Button>
      </Flex>

      <Table dataSource={data} columns={CouponsColumns(onActiveChange)} />

      <br />
      <br />
      <MyModal setData={setItem} open={open} setOpen={setOpen}>
        <h2>{item?.id ? translations.editCoupon : translations.addCoupon}</h2>
        <Flex vertical>
          <Text>{translations.minOrder} </Text>
          <Input
            value={item?.minimumOrder}
            onChange={({ target: { value } }) => {
              setItem({
                ...item,
                minimumOrder: value,
              });
            }}
            placeholder={translations.minOrder}
            style={{
              marginRight: 12,
              marginBottom: 12,
            }}
          />
        </Flex>
        <br />
        <Flex vertical>
          <Text>{translations.type} </Text>
          <Switch
            style={{ maxWidth: 100 }}
            checkedChildren="%"
            unCheckedChildren="&euro;"
            checked={item?.type}
            onChange={() => {
              setItem({
                ...item,
                type: !item?.type,
              });
            }}
          />
        </Flex>
        <br />
        <Flex vertical>
          <Text>{translations.discount} (€)</Text>
          <Input
            autoFocus
            value={item?.discount}
            onChange={({ target: { value } }) => {
              setItem({
                ...item,
                discount: value,
              });
            }}
            placeholder={translations.discount}
            style={{
              marginRight: 12,
              marginBottom: 12,
            }}
          />
        </Flex>
        <br />

        <Button onClick={onFinish} style={{ width: 100 }}>
          {translations.submit}
        </Button>
      </MyModal>
    </Flex>
  );
};

export default CouponsTable;
