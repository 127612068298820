import React from "react";
import { Button, Card, Flex, Form, Input } from "antd";
import Logo from "../../assets/Logo";
import { translations } from "../../utils/translations";

const LoginForm = ({ setLoginForm, loginForm, onFinish }) => {
  const { innerWidth } = window;
  return (
    <Flex
      vertical
      justify="center"
      align="center"
      style={{ marginTop: "10svh" }}>
      <Logo />

      <Form
        name="basic"
        style={
          innerWidth > 1000
            ? { width: "100%", minWidth: 200, maxWidth: 300 }
            : { width: "100%" }
        }
        initialValues={{ remember: true }}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="on">
        <Flex justify="center" align="center" vertical>
          <Card style={{ width: "100%" }}>
            <Form.Item
              label={translations.email}
              name="email"
              rules={[{ required: true, message: translations.emailError }]}>
              <Input
                type="email"
                onChange={({ target: { value } }) =>
                  setLoginForm({ ...loginForm, userName: value })
                }
              />
            </Form.Item>

            <Form.Item
              label={translations.password}
              name="password"
              rules={[{ required: true, message: translations.passwordError }]}>
              <Input.Password
                onChange={({ target: { value } }) =>
                  setLoginForm({ ...loginForm, password: value })
                }
              />
            </Form.Item>
          </Card>
          <br />
          <Form.Item>
            <Button style={{ width: 120 }} type="primary" htmlType="submit">
              {translations.submit}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Flex>
  );
};

export default LoginForm;
