import { Flex, Input, InputNumber, Switch } from "antd";
import React from "react";
import { translations } from "../../../../utils/translations";

const ModifierOptionROw = ({ item, setItem }) => {
  return (
    <Flex
      gap={16}
      vertical
      justify="space-around"
      align="flex-start"
      style={{ marginBottom: 12 }}>
      <Flex vertical>
        <span>
          <sup style={{ marginRight: 4, color: "red" }}>*</sup>
          {translations.name}
        </span>
        <Input
          value={item?.name || null}
          onChange={(e) => {
            setItem({
              ...item,
              name: e.target.value,
            });
          }}
        />
      </Flex>
      <Flex vertical>
        <span>{translations.price}</span>
        <InputNumber
          value={item?.price || 0}
          onChange={(value) => {
            String(value).length < 6
              ? setItem({
                  ...item,
                  price: value,
                })
              : null;
          }}
        />
      </Flex>
      <Flex vertical>
        <span>{translations.additionalTax}</span>
        <InputNumber
          value={item?.additionalTax || 0}
          onChange={(value) => {
            String(value).length < 6
              ? setItem({
                  ...item,
                  additionalTax: value,
                })
              : null;
          }}
        />
      </Flex>
      <Flex vertical>
        <span>{translations.available}</span>
        <Switch
          checked={item?.available || null}
          onChange={(value) => {
            setItem({
              ...item,
              available: value,
            });
          }}
        />
      </Flex>
    </Flex>
  );
};

export default ModifierOptionROw;
