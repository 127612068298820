import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  users: [],
  shops: [],
  cuisines: [],
  itemCategories: [],
  items: [],
  modifiers: [],
  modifierOptions: [],
  deals: [],
  coupons: [],
  orders: [],
  open: false,
  currentTab: "1",
  loading: false,
  error: null,
  theme: "dark",
  cities: [],
};

export const stateSlice = createSlice({
  name: "slicer111",
  initialState,
  reducers: {
    clearPersistedState(state, action) {
      return initialState;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUsers: (state, action) => {
      if (action.payload.length > 0) {
        state.users = action.payload;
      }
    },
    setShops: (state, action) => {
      state.shops = action.payload;
      state.loading = false;
    },
    setCuisine: (state, action) => {
      state.cuisines = action.payload;
      state.loading = false;
    },
    setItemCategories: (state, action) => {
      state.itemCategories = action.payload;
      state.loading = false;
    },
    setItems: (state, action) => {
      state.items = action.payload;
      state.loading = false;
    },
    setModifiers: (state, action) => {
      state.modifiers = action.payload;
      state.loading = false;
    },
    setModifierOptions: (state, action) => {
      state.modifierOptions = action.payload;
      state.loading = false;
    },

    setDeals: (state, action) => {
      state.deals = action.payload;
      state.loading = false;
    },
    setCoupons: (state, action) => {
      state.coupons = action.payload;
      state.loading = false;
    },
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setCities: (state, action) => {
      state.cities = action.payload;
    },
  },
});

export const {
  clearPersistedState,
  setUser,
  setUsers,
  setShops,
  setCuisine,
  setItemCategories,
  setItems,
  setModifiers,
  setModifierOptions,
  setOpen,
  setCurrentTab,
  setLoading,
  setError,
  setDeals,
  setCoupons,
  setTheme,
  setOrders,
  setCities,
} = stateSlice.actions;

export default stateSlice.reducer;
