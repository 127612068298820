import React from "react";
import { useSelector } from "react-redux";
import { Flex, Spin, Switch, Typography } from "antd";
import LiveOrders from "./store/LiveOrders";
import { translations } from "../utils/translations";

const { Title } = Typography;

const Home = () => {
  const { user } = useSelector((state) => state);

  return !user.id ? (
    <Flex justify="center" align="center" style={{ height: "10vh" }}>
      <Spin />
    </Flex>
  ) : (
    <div style={{ padding: 24 }}>
      {user && user?.role === 0 ? (
        <Flex justify="space-between" align="center" style={{ height: "10vh" }}>
          <Title>Hello Big Kota Channel</Title>
        </Flex>
      ) : (
        <div>
          <Flex justify="space-between" align="center">
            <Title>{translations.liveOrders}</Title>
            <Switch
              checkedChildren={"Active"}
              unCheckedChildren="Inactive"
              defaultChecked={true}
              onChange={(value) => console.log({ value })}
            />
          </Flex>
          <LiveOrders />
        </div>
      )}
    </div>
  );
};

export default Home;
