import { EyeOutlined, SettingOutlined } from "@ant-design/icons";
import { Collapse, Flex, Select } from "antd";
import React, { useState } from "react";

const Order = ({ orderNumber, orderAddress, orderDetails }) => {
  const { innerWidth } = window;

  const [status, setStatus] = useState(0);
  const genExtra = () => (
    <EyeOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );

  const backColors = {
    0: "#f50",
    1: "#3db389",
    2: "#108ee9",
    3: "#9e9e9e",
  };

  return (
    <div style={{ marginBottom: 12 }}>
      <Flex
        justify="space-between"
        style={{
          backgroundColor: backColors[status],
          padding: 4,
          borderTopRightRadius: 6,
          borderTopLeftRadius: 6,
        }}>
        <div>
          <div>#{orderNumber}</div>
          <div>{orderAddress} </div>
          <div>distance: 1.2km </div>
          <br />
          <span>Order:</span>
          <br />
          <br />
          {orderDetails.map((item, index) => (
            <div key={index} style={{ marginBottom: 6 }}>
              {index + 1}. &nbsp;{item}
            </div>
          ))}
        </div>
        <Select
          style={innerWidth < 450 ? { width: "50%" } : { width: "20%" }}
          value={status}
          options={[
            { value: 0, label: "incoming" },
            { value: 1, label: "in Prep" },
            { value: 2, label: "Ready" },
            { value: 3, label: "delivered" },
          ]}
          onChange={(value) => {
            setStatus(value);
          }}
        />
      </Flex>
      <Collapse
        style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
        items={[
          {
            label: "Details",
            children: <div></div>,
            showArrow: false,
            extra: genExtra(),
          },
        ]}
      />
    </div>
  );
};

export default Order;
