import { Flex, Form, Input } from "antd";
import React from "react";
import PhoneInput from "react-phone-number-input";
import { translations } from "../../../../utils/translations";

const User = ({ shopData, setShopData }) => {
  return (
    <div>
      <Flex vertical style={{ marginRight: 6 }}>
        <br />
        <h3>{translations.firstName}</h3>
        <Input
          style={{ width: 200 }}
          value={shopData?.user.firstName}
          onChange={({ target: { value } }) => {
            setShopData({
              ...shopData,
              user: {
                ...shopData.user,
                firstName: value,
              },
            });
          }}
        />
      </Flex>
      <br />
      <Flex vertical style={{ marginRight: 6 }}>
        <h3>{translations.lastName}</h3>
        <Input
          style={{ width: 200 }}
          value={shopData?.user.lastName}
          onChange={({ target: { value } }) => {
            setShopData({
              ...shopData,
              user: {
                ...shopData.user,
                lastName: value,
              },
            });
          }}
        />
      </Flex>
      <br />
      <Flex vertical style={{ marginRight: 6 }}>
        <h3>{translations.email}</h3>
        <Input
          type="email"
          style={{ width: 200 }}
          value={shopData?.user.email}
          onChange={({ target: { value } }) => {
            setShopData({
              ...shopData,
              user: {
                ...shopData.user,
                email: value,
              },
            });
          }}
        />
      </Flex>
      <br />
      <Flex vertical style={{ marginRight: 6 }}>
        <h3>{translations.phone}</h3>
        <PhoneInput
          value={shopData?.user.phone}
          defaultCountry="GR"
          style={{
            width: 200,
            border: "1px solid #cecece",
            borderRadius: 6,
            marginTop: 2,
            height: 30,
          }}
          onChange={(value) => {
            setShopData({
              ...shopData,
              user: {
                ...shopData.user,
                phone: value,
              },
            });
          }}
        />
      </Flex>
    </div>
  );
};

export default User;
