import React from "react";
import { Button, Flex, Typography } from "antd";
import { UsersColumns } from "../../columns/UsersColumns";
import { useNavigate } from "react-router-dom";
import { RoutesNames } from "../../router/RouteNames";
import MyTable from "../UI/Table";
import { setUsers } from "../../redux/Reducer";
import { useSelector } from "react-redux";
import { translations } from "../../utils/translations";

const { Title } = Typography;
const UsersTable = ({
  data,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  totalPages,
}) => {
  const navigate = useNavigate();

  return (
    <Flex vertical>
      <Flex justify="space-between" align="center">
        <Title>{translations.users}</Title>

        <Button
          type="primary"
          onClick={() => {
            navigate(RoutesNames.ADD_USERS);
          }}>
          {translations.addUser}
        </Button>
      </Flex>

      <MyTable
        data={data}
        rowKey="id"
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalPages={totalPages}
        editPath="/users/edit/"
        basePath="/users/"
        queryParams="id"
        columns={UsersColumns}
        setReduxAction={setUsers}
        draggable={false}
      />
    </Flex>
  );
};

export default UsersTable;
