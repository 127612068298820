import React, { useEffect, useState } from "react";
import api from "../../../utils/http";
import ShopForm from "../../../components/store/shop";
import { useSelector } from "react-redux";
import { message } from "antd";

const Shop = () => {
  const { user, currentTab } = useSelector((state) => state);

  const { get } = api();

  const [shopData, setShopData] = useState({
    address: {},
    user: {},
    name: "",
    phone: "",
    schedule: [
      ["08:00", "22:00"],
      ["08:00", "22:00"],
      ["08:00", "22:00"],
      ["08:00", "22:00"],
      ["08:00", "22:00"],
      ["08:00", "22:00"],
      ["08:00", "22:00"],
    ],
    files: [],
    cuisines: [],
  });

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    currentTab === "1" &&
      user.shop &&
      get("")
        .then((response) => {
          console.log({ response });
          setShopData({
            ...response.data,
            schedule: response.data.schedule.map((item) =>
              item.length === 0 ? ["0000", "0000"] : item
            ),
            areas: response.data.areas.map((item) => ({
              ...item,
              fillColor: getRandomColor(),
            })),
          });
        })
        .catch((error) => message.error(error.response.data.message));
  }, [currentTab, user.shop]);

  return <ShopForm shopData={shopData} setShopData={setShopData} user={user} />;
};

export default Shop;
