import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { RoutesNames } from "../../router/RouteNames";
import api from "../../utils/http";
import { useSelector } from "react-redux";
import CityForm from "../../components/cities/CityForm";

const EditCityHOC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useSelector((state) => state);

  const { putGlobal, getGlobal } = api();

  const [item, setItem] = useState(null);

  useEffect(() => {
    params.id &&
      getGlobal(`/cities/${params.id}`)
        .then((response) => {
          setItem({
            lat: response.data.area.circleCenter.lat,
            lng: response.data.area.circleCenter.lng,
            radius: response.data.area.radius,
            name: response.data.name,
          });
        })
        .catch((err) => {
          message.error(err.response.data.message);
        });
  }, [params.id]);

  const onFinish = () => {
    putGlobal(`/cities/${params.id}`, {
      area: {
        type: 0,
        circleCenter: {
          lat: item.lat,
          lng: item.lng,
        },
        radius: parseInt(item.radius),
      },
      name: item.name,
      language: user.language,
    })
      .then(() => {
        message.success("City updated successfully");
        navigate(RoutesNames.CITIES);
      })
      .catch((error) => message.error(error.response.data.message));
  };

  return (
    <CityForm
      onFinish={onFinish}
      cityData={item}
      setCityData={setItem}
      edit={true}
    />
  );
};

export default EditCityHOC;
