import React, { useEffect, useState } from "react";
import {
  Collapse,
  Flex,
  Tree,
  message,
  Tag,
  Typography,
  Select,
  Switch,
  Divider,
} from "antd";
import Info from "../store/shop/components/Info";
import ItemCategoryForm from "../store/shop/itemCategories/ItemCategoryForm";
import MyModal from "../UI/Modal";
import ItemForm from "../store/shop/items/ItemsForm";
import ModifierForm from "../store/shop/modifiers/ModifierForm";
import ModifierOptionsForm from "../store/shop/modifierOptions/ModifierOptionsForm";
import { useDispatch } from "react-redux";
import api from "../../utils/http";
import { translations } from "../../utils/translations";

const { Text } = Typography;
const TreeCompo = ({
  shopData,
  setShopData,
  data,
  onFinish,
  user,
  open,
  setOpen,
  selectedID,
  depth,
  setDepth,
  addData,
  setAddData,
  coupons,
  onActiveChange,
}) => {
  const dispatch = useDispatch();
  const { patch, put } = api();

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [gData, setGData] = useState([]); // Local state to handle reordering

  useEffect(() => {
    setGData(data); // Sync local gData state with data from props
  }, [data]);

  const onSelect = (selectedKeys, info) => {
    const key = info.node.key;
    const newExpandedKeys = expandedKeys.includes(key)
      ? expandedKeys.filter((k) => k !== key)
      : [...expandedKeys, key];

    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onDragEnter = (info) => {
    console.log("onDragEnter", info);
  };

  const onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPositionRelative =
      info.dropPosition - Number(info.node.pos.split("-").pop());

    // Helper to find node by key, including its index and parent
    const findNodeByKey = (nodes, key, parent = null) => {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].key === key) {
          return { node: nodes[i], index: i, parent };
        }
        if (nodes[i].children) {
          const found = findNodeByKey(nodes[i].children, key, nodes[i]);
          if (found) return found;
        }
      }
      return null;
    };

    const dataClone = [...gData];

    const {
      node: dragNode,
      index: dragIndex,
      parent: dragParent,
    } = findNodeByKey(dataClone, dragKey) || {};
    const {
      node: dropNode,
      index: dropIndex,
      parent: dropParent,
    } = findNodeByKey(dataClone, dropKey) || {};

    // Only reorder if nodes have the same parent
    if (dragParent !== dropParent || !dragNode || !dropNode) {
      message.error(translations.message.error.itemMove);
      return;
    }

    // Remove dragNode from its original position
    const siblings = dragParent ? dragParent.children : dataClone;
    siblings.splice(dragIndex, 1);

    // Insert dragNode at the new position within the same level
    const newIndex = dropPositionRelative === -1 ? dropIndex : dropIndex + 1;
    siblings.splice(newIndex, 0, dragNode);

    setGData(dataClone); // Update local gData state
    setShopData(dataClone); // Update parent data to reflect the reordered structure
  };

  const apiCallADD = {
    1: "/item-categories",
    2: "/items",
    3: "/modifiers",
    4: "/modifier-options",
  };

  const apiCallEdit = {
    1: "/item-categories/" + selectedID,
    2: "/items/" + selectedID,
    3: "/modifiers/" + selectedID,
    4: "/modifier-options/" + selectedID,
  };

  const onFinishLocal = () => {
    const apiMethod = selectedID ? put : patch;
    const apiCall = selectedID ? apiCallEdit[depth] : apiCallADD[depth];
    const dataPayload = {
      ...addData,
      ...(addData.files && { files: addData.files.map((x) => x.id) }),
      ...(addData.price && { price: addData.price * 100 }),
      ...(addData.additionalTax >= 0 && {
        additionalTax: addData.additionalTax * 100,
      }),
      language: user.language,
    };

    dispatch(apiMethod(apiCall, dataPayload))
      .then((response) => {
        setAddData({});
        setOpen(false);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  const modalComponent = {
    1: (
      <ItemCategoryForm
        item={addData}
        setItem={setAddData}
        onFinish={onFinishLocal}
      />
    ),
    2: (
      <ItemForm
        item={addData}
        setItem={setAddData}
        onFinish={onFinishLocal}
        user={user}
      />
    ),
    3: (
      <ModifierForm
        item={addData}
        setItem={setAddData}
        onFinish={onFinishLocal}
        user={user}
        edit={!!selectedID}
      />
    ),
    4: (
      <ModifierOptionsForm
        item={addData}
        setItem={setAddData}
        onFinish={onFinishLocal}
      />
    ),
  };

  return (
    <Flex vertical>
      <Collapse ghost>
        <Collapse.Panel header={translations.myShop} key="1">
          <Info
            setShopData={setShopData}
            shopData={shopData}
            user={user}
            onFinish={onFinish}
          />
        </Collapse.Panel>
        <Collapse.Panel header={translations.coupons} key="2">
          {coupons.map((coupon) => (
            <Flex vertical key={coupon.id}>
              <Flex justify="space-between">
                <Tag color={coupon.active ? "green" : "red"}>{coupon.code}</Tag>
                <Text>
                  {translations.discount}:
                  {`${coupon.discount} ${coupon.type === 0 ? "€" : "%"}`}
                </Text>
                <Text>
                  {translations.minOrder}: {coupon.minimumOrder}
                </Text>
                <Switch
                  checked={coupon.active}
                  onChange={() => onActiveChange(coupon.id)}
                />
              </Flex>
              <Divider />
            </Flex>
          ))}
        </Collapse.Panel>
        <Collapse.Panel header={translations.editData} key="3">
          <Flex
            vertical
            justify="center"
            align="center"
            style={{ width: "100%" }}>
            <Text>{translations.addNew}</Text>
            <Select
              value={depth}
              style={{ marginBottom: 12, width: 130 }}
              onChange={(value) => {
                setDepth(value);
                setOpen(true);
              }}
              options={[
                { value: 1, label: <Text>{translations.itemCat}</Text> },
                { value: 2, label: <Text>{translations.item}</Text> },
                { value: 3, label: <Text>{translations.modifier}</Text> },
                { value: 4, label: <Text>{translations.modifierOption}</Text> },
              ]}
            />
          </Flex>
          <Tree
            onSelect={onSelect}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            draggable
            showLine
            treeData={gData} // Use the local gData state for rendering
          />
        </Collapse.Panel>
      </Collapse>
      <MyModal open={open} setOpen={setOpen} setData={setDepth}>
        {modalComponent[depth]}
      </MyModal>
    </Flex>
  );
};

export default TreeCompo;
