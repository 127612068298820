import React, { useEffect, useState } from "react";
import { setItems } from "../../../../redux/Reducer";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../../utils/http";
import ItemsView from "../../../../components/store/shop/items/ItemsView";
import { message } from "antd";

const ItemsHOC = () => {
  const { items, user } = useSelector((state) => state);
  const { get, patch, put } = api();
  const dispatch = useDispatch();

  const initialState = {
    name: "",
    category: "",
    price: "",
    description: "",
    available: true,
    files: [],
    modifiers: [],
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [item, setItem] = useState(initialState);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    user?.shop &&
      !open &&
      get(`/items`, { pageNumber, pageSize })
        .then((response) => {
          response && dispatch(setItems(response.data.data));
          setTotalPages(response.data.totalPages);
        })
        .catch((err) => {
          message.error(err.response.data.message);
        });
  }, [pageNumber, pageSize, open, user]);

  const onFinish = () => {
    item.id
      ? put(`/items/${item.id}`, {
          name: item.name,
          shop: user.shop,
          language: user.language,
          category: item.category,
          price: item.price * 100,
          available: !!item.available,
          files: item.files?.[0]?.id
            ? item.files?.map((file) => file.id)
            : item?.files,
          modifiers: item?.modifiers?.[0]?.id
            ? item?.modifiers?.map((modifier) => modifier.id)
            : item?.modifiers,
          description: item.description,
        })
          .then((response) => {
            setOpen(!open);
            message.success("Success!");
            setItem({ initialState });
          })
          .catch((err) => {
            message.error(err.response.data.message);
          })
      : patch(`/items`, {
          name: item.name,
          price: item.price * 100,
          category: item.category,
          available: !!item.available,
          files: item.files?.map((file) => file.id) || [],
          description: item.description,
          modifiers: item.modifiers || [],
          language: user.language,
        })
          .then((response) => {
            setOpen(!open);
            message.success("Success!");
            setItem({});
          })
          .catch((err) => {
            message.error(err.response.data.message);
          });
  };

  return (
    <ItemsView
      data={items}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      pageSize={pageSize}
      setPageSize={setPageSize}
      totalPages={totalPages}
      item={item}
      setItem={setItem}
      onFinish={onFinish}
      open={open}
      setOpen={setOpen}
      user={user}
    />
  );
};

export default ItemsHOC;
