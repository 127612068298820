import React, { useEffect, useState } from "react";
import { Flex, Spin, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { RoutesNames } from "../../router/RouteNames";
import api from "../../utils/http";
import CuisineForm from "../../components/cuisines/CuisineForm";
import { useSelector } from "react-redux";

const EditCuisineHOC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useSelector((state) => state);

  const { putGlobal, getGlobal } = api();

  const [cuisineData, setCuisineData] = useState(null);

  useEffect(() => {
    params.id &&
      getGlobal(`/cuisines/${params.id}?language=${user.language}`)
        .then((response) => {
          setCuisineData(response.data);
        })
        .catch((err) => {
          message.error(err.response.data.message);
        });
  }, [params.id]);

  const onFinish = () => {
    putGlobal(`/cuisines/${params.id}`, {
      files: cuisineData.files.map((file) => file.id),
      name: cuisineData.name,
      language: user.language,
    })
      .then(() => {
        message.success("Cuisine updated successfully");
        navigate(RoutesNames.CUISINES);
      })
      .catch((error) => message.error(error.response.data.message));
  };
  return (
    <CuisineForm
      onFinish={onFinish}
      cuisineData={cuisineData}
      setCuisineData={setCuisineData}
      edit={true}
    />
  );
};

export default EditCuisineHOC;
