import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  ConfigProvider,
  Divider,
  Drawer,
  Flex,
  Menu,
  Select,
  Spin,
  Typography,
} from "antd";
import {
  HistoryOutlined,
  LogoutOutlined,
  SettingOutlined,
  SunFilled,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab, setShops, setTheme, setUser } from "../redux/Reducer";
import Home from "../components/Home";
import UsersHOC from "../containers/users/UsersHOC";
import AddUserHOC from "../containers/users/AddUserHOC";
import EditUserHOC from "../containers/users/EditUserHOC";
import CuisinesHOC from "../containers/cuisines/CuisineHOC";
import AddCuisineHOC from "../containers/cuisines/AddCuisineHOC";
import EditCuisineHOC from "../containers/cuisines/EditCuisineHOC";
import ShopsHOC from "../containers/shops/ShopsHOC";
import Settings from "../components/store/Settings";
import HistoryOrders from "../components/store/HistoryOrders";
import LoginHOC from "../containers/auth/LoginHOC";
import LanguagesHoc from "../containers/languages/LanguagesHOC";
import ShopHOC from "../containers/shopOwner/shop/ShopHOC";
import LoadingOrError from "../components/LoadingOrError";
import { RoutesNames } from "./RouteNames";
import { useTheme } from "../utils/useTheme";
import "../index.css";
import { translations } from "../utils/translations";
import LogoImg from "../assets/LogSmall";
import api from "../utils/http";
import CityHOC from "../containers/cities/CityHOC";
import AddCityHOC from "../containers/cities/AddCityHOC";
import EditCityHOC from "../containers/cities/EditCityHOC";
import Print from "../components/Print";
import Tree from "../containers/tree";

const { Title } = Typography;

const Router = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { postGlobal, getGlobal } = api();

  const { color } = useTheme();

  const { user, theme, shops } = useSelector((state) => state);
  const [current, setCurrent] = useState("mail");
  const [openDrawer, setOpenDrawer] = useState(false);

  const HandleChangeMenuItem = (e) => {
    navigate(e.key);
    setCurrent(e.key);
  };

  const isTokenExpired = () => {
    if (!user?.expirationTime) return true; // No token means it's effectively expired
    return Date.now() > user.expirationTime;
  };

  useEffect(() => {
    if (isTokenExpired() && user?.refreshToken) {
      postGlobal("/auth/refresh-token", { token: user?.refreshToken })
        .then((response) => {
          const { token, expirationTime } = response.data;
          dispatch(
            setUser({ token, expirationTime, refreshToken: user?.refreshToken })
          );
        })
        .catch((error) => {
          console.error(error);
          dispatch(setUser(null));
        });
    }

    if (!window.location.pathname.includes("/shop/")) {
      dispatch(setUser({ ...user, shop: null }));
    } else {
      dispatch(
        setUser({
          ...user,
          shop: parseInt(window.location.pathname.split("/")[2]),
        })
      );
    }
  }, [window.location.pathname]);

  useEffect(() => {
    user?.token &&
      user?.role === 0 &&
      getGlobal("/shops")
        .then((res) => {
          dispatch(setShops(res.data.data));
        })
        .catch((err) => {
          console.log(err);
        });
  }, [user]);

  const menuItems =
    user?.role === 0
      ? [
          {
            label: translations.home,
            key: RoutesNames.HOME,
          },
          {
            label: translations.users,
            key: RoutesNames.USERS,
          },
          {
            label: translations.shops,
            key: RoutesNames.SHOPS,
          },
          {
            label: translations.cuisines,
            key: RoutesNames.CUISINES,
          },
          {
            label: translations.cities,
            key: RoutesNames.CITIES,
          },
          {
            label: "Print",
            key: RoutesNames.PRINT,
          },
        ]
      : [
          {
            label: <LogoImg />,
            key: RoutesNames.HOME,
          },
        ];

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 14,
          colorPrimary: "#179651",
          colorText: color.TEXT,
          colorBgContainer: color.BACKGROUND,
          colorTextDescription: color.TEXT,
          colorBgBase: color.BACKGROUND,
          colorFillContent: "grey",
          colorTextDisabled: "grey",
          colorIcon: color.TEXT,
          colorIconHover: color.TEXT,
        },
        components: {
          Select: {
            selectorBg: color.BACKGROUND,
            optionSelectedBg: color.BUTTON,
            optionActiveBg: color.BACKGROUND,
            colorIcon: color.TEXT,
            colorBorder: color.BORDER,
            colorTextPlaceholder: color.BORDER,
          },
          Collapse: {
            colorBgContainer: color.BACKGROUND,
            colorBorder: color.BORDER,
          },
          Input: {
            colorBgContainer: color.BACKGROUND,
            colorBorder: color.BORDER,
            colorTextPlaceholder: color.BORDER,
          },
          Popover: {
            colorBgElevated: color.BUTTON,
          },
          Pagination: {
            colorBgBase: color.BACKGROUND,
            colorBgElevated: color.BACKGROUND,
            itemActiveBg: color.BACKGROUND,
          },
          Button: {
            colorBorder: color.BORDER,
            primaryShadow: "0px 0px 0px transparent",
          },
          Checkbox: {
            colorBorder: color.BORDER,
          },
          Card: {
            colorBorderSecondary: "#545454",
            colorBorder: color.BORDER,
          },
          DatePicker: {
            colorBorder: color.BORDER,
            colorTextPlaceholder: color.BORDER,
          },
          Typography: {
            colorText: color.TEXT,
            colorTextHeading: color.TEXT,
            fontSizeHeading1: 28,
          },
        },
      }}>
      <div style={{ backgroundColor: color.BACKGROUND, height: 50 }}>
        {user?.token && (
          <Flex
            justify="space-between"
            align="center"
            style={{
              height: 50,
              width: "100%",
            }}>
            {user?.role === 1 ? (
              <h2
                onClick={() => {
                  navigate(RoutesNames.HOME);
                }}>
                <LogoImg />
              </h2>
            ) : (
              <Menu
                style={{ width: "100%" }}
                onClick={HandleChangeMenuItem}
                selectedKeys={[current]}
                mode="horizontal"
                items={menuItems}
              />
            )}
            {(user?.role === 0 || user?.role === 2) && (
              <Select
                allowClear
                placeholder={translations.selectShop}
                style={{ width: 200 }}
                options={shops.map((shop) => ({
                  label: shop.name,
                  value: shop.id,
                }))}
                onChange={(value) =>
                  value ? navigate(`/shop/${value}`) : navigate(`/shops`)
                }
              />
            )}
            <UserOutlined
              style={{
                color: color.TEXT,
                padding: 16,
                borderRadius: 4,
              }}
              onClick={() => {
                setOpenDrawer(true);
              }}
            />
          </Flex>
        )}

        <div
          style={{
            backgroundColor: color.BACKGROUND,
            height: "auto",
            minHeight: "100svh",
            paddingTop: "5svh",
            padding: 24,
            overflowX: "hidden",
          }}>
          {!user?.token && !user?.language ? (
            <Routes>
              <Route path="*" element={<LoginHOC />} />
            </Routes>
          ) : (
            <LoadingOrError>
              <Routes>
                <Route index element={<Home />} />
                {/* users routes */}
                <Route path={RoutesNames.USERS} element=<UsersHOC /> />
                <Route path={RoutesNames.ADD_USERS} element={<AddUserHOC />} />
                <Route path={RoutesNames.EDIT_USER} element={<EditUserHOC />} />
                {/* shops routes */}
                <Route path={RoutesNames.SHOPS} element={<ShopsHOC />} />
                <Route path={RoutesNames.ADD_SHOP} element={<ShopHOC />} />
                {/* cuisines routes */}
                <Route path={RoutesNames.CUISINES} element={<CuisinesHOC />} />
                <Route
                  path={RoutesNames.ADD_CUISINES}
                  element={<AddCuisineHOC />}
                />
                <Route
                  path={RoutesNames.EDIT_CUISINE}
                  element={<EditCuisineHOC />}
                />

                {/* Languages */}
                <Route
                  path={RoutesNames.LANGUAGES}
                  element={<LanguagesHoc />}
                />
                {/* Languages */}
                <Route path={RoutesNames.CITIES} element={<CityHOC />} />
                <Route path={RoutesNames.ADD_CITY} element={<AddCityHOC />} />
                <Route path={RoutesNames.EDIT_CITY} element={<EditCityHOC />} />

                {/* Store Role routes */}
                <Route path={RoutesNames.HISTORY} element={<HistoryOrders />} />
                <Route
                  path={RoutesNames.SHOP}
                  element={user.role === 0 ? <ShopHOC /> : <Tree />}
                />
                <Route path={RoutesNames.SETTINGS} element={<Settings />} />
                {/* print */}
                <Route path={RoutesNames.PRINT} element={<Print />} />
              </Routes>
            </LoadingOrError>
          )}
        </div>
        <Drawer
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          title={`${translations.hello} ${user?.firstName} ${user?.lastName}`}>
          <Flex
            vertical
            justify="space-between"
            align="flex-start"
            style={{ height: "85svh" }}>
            <div>
              {(user?.role === 1 || user?.role === 3) && (
                <Flex
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(RoutesNames.HOME);
                    setOpenDrawer(false);
                  }}>
                  <Title level={5}>
                    <UnorderedListOutlined
                      style={{ marginRight: 12, fontSize: 22 }}
                    />
                    {translations.liveOrders}
                  </Title>
                </Flex>
              )}
              <br />
              {(user?.role === 1 || user?.role === 3) && (
                <Flex
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(RoutesNames.HISTORY);
                    setOpenDrawer(false);
                  }}>
                  <Title level={5}>
                    <HistoryOutlined
                      style={{ marginRight: 12, fontSize: 22 }}
                    />
                    {translations.orderHistory}
                  </Title>
                </Flex>
              )}
              <br />
              {(user?.role === 1 || user?.role === 3) && (
                <Flex
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(setCurrentTab("1"));
                    navigate("/shop/" + user?.shop);
                    setOpenDrawer(false);
                  }}>
                  <Title level={5}>
                    <SettingOutlined
                      style={{ marginRight: 12, fontSize: 22 }}
                    />
                    {translations.myShop}
                  </Title>
                </Flex>
              )}{" "}
            </div>
            <div>
              <Divider style={{ backgroundColor: "#efefef", width: 320 }} />
              <Flex
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(setTheme(theme === "dark" ? "light" : "dark"));
                }}>
                <SunFilled
                  style={{ marginRight: 12, fontSize: 22, color: color.TEXT }}
                />
                <span style={{ color: color.TEXT, fontSize: 18 }}>
                  {theme === "dark"
                    ? translations.activateLightMode
                    : translations.activateDarkMode}
                </span>
              </Flex>
              <br />
              <Flex
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(setUser({}));
                  setOpenDrawer(false);
                  setCurrent("/");
                }}>
                <LogoutOutlined
                  style={{ marginRight: 12, fontSize: 22, color: color.TEXT }}
                />
                <span style={{ fontSize: 18, color: color.TEXT }}>
                  {translations.logout}
                </span>
              </Flex>
            </div>
          </Flex>
        </Drawer>
      </div>
    </ConfigProvider>
  );
};

export default Router;
