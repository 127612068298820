import { Button, Flex, Spin, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "../redux/Reducer";

const { Title } = Typography;

const LoadingOrError = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => state);
  if (loading) {
    return (
      <Flex
        justify="center"
        align="center"
        style={{ width: "100%", height: 200 }}>
        <Spin size="large" />
      </Flex>
    );
  } else if (!loading && error) {
    return (
      <Flex
        justify="center"
        align="center"
        style={{ width: "100%", height: 200 }}>
        <Title level={3}>{error}</Title>
        <Button
          type="primary"
          onClick={() => {
            dispatch(setError(null));
            navigate("/");
          }}>
          Go Home
        </Button>
      </Flex>
    );
  } else if (!loading && !error) {
    return <div>{children}</div>;
  }
};

export default LoadingOrError;
