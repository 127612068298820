import React from "react";
import UserForm from "../../components/users/UserForm";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { RoutesNames } from "../../router/RouteNames";
import api from "../../utils/http";
import { useSelector } from "react-redux";

const AddUserHOC = ({ status, id }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);
  const { postGlobal } = api();

  const onFinish = (values) =>
    postGlobal("/users/support", {
      ...values,
      role: 3,
      language: user.language,
    })
      .then(() => {
        message.success("User created successfully");
        navigate(RoutesNames.USERS);
      })
      .catch((error) => message.error(error.response.data.message));

  return <UserForm edit={false} onFinish={onFinish} userData={null} />;
};

export default AddUserHOC;
