import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModifierOptions } from "../../../../redux/Reducer";
import api from "../../../../utils/http";
import ModifierOptionsView from "../../../../components/store/shop/modifierOptions/ModifierOptionsView";
import { message } from "antd";

const ModifiersOptionsHOC = () => {
  const { modifierOptions, user } = useSelector((state) => state);
  const { get, patch, put } = api();
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [item, setItem] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    user?.shop &&
      !open &&
      get(`/modifier-options`, {
        pageNumber,
        pageSize,
      })
        .then((response) => {
          response && dispatch(setModifierOptions(response.data.data));
          setTotalPages(response.data.totalPages);
        })
        .catch((err) => {
          message.error(err.response.data.message);
        });
  }, [pageNumber, pageSize, open, user]);

  const onFinish = () => {
    item.id
      ? put(`/modifier-options/${item.id}`, {
          name: item.name,
          price: item.price * 100 || 0,
          additionalTax: item.additionalTax * 100 || 0,
          available: item.available,
          shop: user.shop,
          language: user.language,
        })
          .then((response) => {
            setOpen(!open);
            setItem({});
            message.success("Success!");
          })
          .catch((err) => {
            message.error(err.response.data.message);
          })
      : patch(`/modifier-options`, {
          name: item.name,
          price: item.price * 100 || 0,
          additionalTax: item.additionalTax * 100 || 0,
          available: item.available,
          shop: user.shop,
          language: user.language,
        })
          .then((response) => {
            setOpen(!open);
            setItem({});
            message.success("Success!");
          })
          .catch((err) => {
            message.error(err.response.data.message);
          });
  };

  return (
    <ModifierOptionsView
      data={modifierOptions}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      pageSize={pageSize}
      setPageSize={setPageSize}
      totalPages={totalPages}
      item={item}
      setItem={setItem}
      onFinish={onFinish}
      open={open}
      setOpen={setOpen}
      user={user}
    />
  );
};

export default ModifiersOptionsHOC;
