import React, { useEffect } from "react";
import { Button, Card, Flex, Form, Input, Upload } from "antd";
import UploadImage from "../UI/UploadImage";
import { translations } from "../../utils/translations";

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 12,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
  },
  number: {
    range: "${label} must be a valid mobile number! (10 digits)",
  },
};
/* eslint-enable no-template-curly-in-string */

const CuisineForm = ({ edit, onFinish, cuisineData, setCuisineData }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: cuisineData?.name,
      files: cuisineData?.files,
    });
  }, [cuisineData]);

  return (
    <Card
      title={edit ? translations.editCuisine : translations.addCuisine}
      style={{ width: "100%" }}>
      <Form
        {...layout}
        name={translations.cuisines}
        form={form}
        onFinish={onFinish}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        validateMessages={validateMessages}>
        <Form.Item
          style={{ width: "100%" }}
          name={["name"]}
          label={translations.name}
          rules={[
            {
              required: true,
            },
          ]}>
          <Flex
            justify="flex-start"
            align="center"
            style={{ position: "relative" }}>
            <Flex vertical>
              <Flex align="center">
                <Input
                  value={cuisineData?.name}
                  onChange={({ target: { value } }) => {
                    setCuisineData({
                      ...cuisineData,
                      name: value,
                    });
                  }}
                  placeholder={translations.name}
                  style={{
                    marginRight: 12,
                    marginBottom: 12,
                    width: "70svw",
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
        </Form.Item>
        <Form.Item
          style={{ width: "100%" }}
          name={["files"]}
          label={translations.image}
          rules={[
            {
              required: true,
            },
          ]}>
          <Flex
            justify="flex-start"
            align="center"
            style={{ position: "relative" }}>
            <UploadImage
              parentData={cuisineData}
              setParentData={setCuisineData}
              array={[`${translations.main}`]}
            />
          </Flex>
        </Form.Item>

        <Form.Item
          style={{ width: "100%" }}
          wrapperCol={{
            ...layout.wrapperCol,
            offset: 8,
          }}>
          <Button
            style={{ marginRight: 12 }}
            onClick={() => window.history.back()}>
            {translations.cancel}
          </Button>

          <Button type="primary" htmlType="submit">
            {translations.submit}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
export default CuisineForm;
